import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import NavbarMain from './NavbarMain';
import numberToWords from 'number-to-words';
import './pdf.css';
import html2pdf from 'html2pdf.js';
const OfferLetter = React.forwardRef(({ formData, isEnabled, setIsEnabled, loading, sendFormData, currentMonthYear }, ref) => {
  const numberFormatter = new Intl.NumberFormat('en-IN', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  const formatNumber = (value) => {
    if (value == null) return '0';
    return numberFormatter.format(parseInt(value.toString().replace(/,/g, ''), 10) || 0);
  };
  
  return (
    <div ref={ref} className="offer-letter-container">
      <div className="offer-letter">
        <div className="page">
          <div className="header">
            <div className="header-left">
              <h3 style={{ textTransform: 'uppercase', fontFamily: '' }}>MRV  IT  SOLUTIONS  PVT  LTD</h3>
            </div>
           
            <div className="header-right">
              <img src="https://offer-offer-1.s3.ap-south-1.amazonaws.com/images.jpg" alt="Page" />
            </div>
          </div>
          <div className="line" style={{backgroundColor:'black',height:'3px'}}></div>
          <div className="content">
            <p style={{textAlign:'center',fontSize:'23px',fontWeight:'bold'}}>PAYSLIP FOR THE MONTH OF {currentMonthYear}</p>
            <table className="salary-table-pay">
              <tbody>
              
                <tr>
                <td style={{backgroundColor:'#9b999c',width:'175px'}}>Employee Code</td>
                  <td>{formData.employeeCode}</td>
                  <td style={{backgroundColor:'#9b999c',width:'175px'}}>Employee Name</td>
                  <td>{formData.employeeName}</td>
                 
                </tr>
                <tr>
                 
                   <td>Division</td>
                  <td>{formData.division}</td>
                  <td>Zone</td>
                  <td>{formData.zone}</td>
                </tr>
                <tr>
                <td>Branch</td>
                  <td>{formData.branch}</td>
                  <td>Location</td>
                  <td>{formData.location}</td>
                 
                </tr>
                <tr>
                <td>Date of Joining</td>
                  <td>{formData.dateOfJoining}</td>
                  <td>Department</td>
                  <td>{formData.department}</td>
                  
                </tr>
                <tr>
                <td>Designation</td>
                  <td>{formData.designation}</td>
                  <td>PAN No.</td>
                  <td></td>
                  
                </tr>
                <tr>
                <td>PF Account No.</td>
                  <td></td>
                  <td>ESIC Number</td>
                  <td></td>
               
              </tr>
              <tr>
                <td>UAN Number</td>
                <td>{formData.uan}</td>
                <td>Bank Account No.</td>
                <td>{formData.bankAccountNo}</td>
              </tr>
              <tr>
                <td>
                Bank Name
                </td>
                <td>{formData.bankName}</td>
                 <td>Total Working Days</td>
                  <td>{formData.totalWorkingDays}</td>
              </tr>
              <tr>
              <td>Paid Days</td>
                  <td>{formData.paidDays}</td>
                  <td></td>
                  <td></td>
              </tr>
              <tr><td colSpan={4} height={20}></td></tr>
              <tr>
                <td  style={{ textAlign: 'center', fontWeight: 'bold' }}>Earnings</td>
                <td  style={{ textAlign: 'center', fontWeight: 'bold' }}>Amount</td>

                <td  style={{ textAlign: 'center', fontWeight: 'bold' }}>Deductions</td>
                <td  style={{ textAlign: 'center', fontWeight: 'bold' }}>Amount</td>

              </tr>
              <tr>
                <td>Basic Salary</td>
                <td>₹{formatNumber(formData.basicSalary)}</td>
                <td>Provident Fund</td>
                <td>₹{formatNumber(formData.epf)}</td>
              </tr>
              <tr>
                <td>House Rent Allowances</td>
                <td>₹{formatNumber(formData.houseRentAllowances)}</td>
                <td>PT</td>
                <td>₹{formatNumber(formData.professionalTax)}</td>
              </tr>
              <tr>
                <td>Medical Allowances</td>
                
                <td>₹{formatNumber(formData.otherAllowances)}</td>
                <td>Tax Deducted at Source</td>
                <td>₹{formatNumber(formData.eps)}</td>
              </tr>
              <tr>
                <td>Conveyance</td>
                <td>₹{formatNumber(formData.conveyance)}</td>
                <td>Salary Advance</td>
                <td></td>
              </tr>
              <tr>
                <td>Professional Allowances</td>
                <td>₹{formatNumber(formData.professionalAllowances)}</td>
                <td>Other Deductions</td>
                <td>₹{formatNumber(formData.otherdeductions)}</td>
              </tr>
              <tr>
                <td className='bo'>Gross Salary</td>
                <td className='bo'>₹{formatNumber(formData.grosspay)}</td>
                <td>Gross Deductions</td>
                <td>₹{formatNumber(formData.grossdeductions)}</td>
              </tr>
              <tr>
                <td className='bo'  >Net Pay</td>
                <td className='bo'>₹{formatNumber(formData.netPay)}</td>
                <td>

                </td>
                <td></td>
              </tr>
             
            </tbody>
            <br></br>
            <b>Net Pay:</b>₹{formatNumber(formData.netPay)} only 
            <br></br><br></br>
            <b>In words:</b>{formData.amountInWords}
          </table>
         
        </div>
        <div className="footer" style={{marginTop:'100px'}}>
        
<p style={{textAlign:'center'}}>This is a system generated report and does not require signature or stamp.</p>
<div className="line" style={{backgroundColor:'black',height:'2px'}}></div>
          <h1 style={{color: 'rgb(1, 118, 173)',fontFamily: 'Times New Roman, Times, serif' }}>MRV  IT  SOLUTIONS  PVT  LTD</h1>
          <p>
          2nd Floor, West Wing, No.16, Rajiv Gandhi Salai, Karapakkam, Chennai - 600 097.<br />
          Web: www.mrvitsolutions.com E-mail: info@mrvitsolutions.com Ph: 93618 73224
        </p>
        </div>
      </div>
    </div>
   
    <input
      type="checkbox"
      id="enableButton"
      checked={isEnabled}
      onChange={(e) => setIsEnabled(e.target.checked)}

    /><sup>*</sup>
    <span style={{
      color: 'red'
    }}>Must verify all the details before printing the offer letter</span><br></br>
    <button onClick={sendFormData} disabled={!isEnabled} style={{ marginTop: '20px', height: 'fit-content', padding: '5px', fontWeight: 'bold' }}> {loading ? (
      <center>  <div className="loading-spinner"></div></center>
    ) : (
      <p>Genereate Payslip</p>
    )}</button>
    
  </div>


  )
    });

const Pdf = () => {
  const [load, setLoad] = useState(false)

  const [isEnabled, setIsEnabled] = useState(false);
   const [formData, setFormData] = useState({
    employeeName: '',
    employeeId: '',
    division: '',
    zone: '',
    branch: '',
    location: '',
    dateOfJoining: '',
    department: '',
    designation: '',
    pfAccountNo: '',
    uanNumber: '',
    panNumber: '',
    esicNumber: '',
    bankAccountNo: '',
    totalWorkingDays: '',
    paidDays: '',
    lopDays: '',
    grossSalary: '',
    basicSalary: '',
    houseRentAllowances: '',
    professionalAllowances: '',
    conveyance: '',
    otherAllowances: '',
    professionalTax: '',
    epf: '',
    eps: '',
    totalDeductions: '',
    netPay: '',
    amountInWords: '',
    grosspay: '',
    otherdeductions:'',
          grossdeductions:'',
    currentMonthYear:''
  });

  const [show, setShow] = useState(false);
  const numberFormatter = new Intl.NumberFormat('en-IN', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
  const navigate = useNavigate('');
  
  const handleTableChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: parseFloat(value) };
    });
  };
  
  const componentRef = useRef();
  
  const isFormValid = () => {
    return Object.values(formData).every(value => {
      if (typeof value === 'string') {
        return value.trim() !== '';
      }
      return value !== null && value !== undefined && value !== '';
    });
  };
  const [allEmployeeRecords, setAllEmployeeRecords] = useState([]); // Store all employee records here
  const [allEmployeeNames, setAllEmployeeNames] = useState([]); // Store all employee names
  const [filteredSuggestions, setFilteredSuggestions] = useState([]); 
  const [selectedDate, setSelectedDate] = useState('');
  const [suggestions, setSuggestions] = useState([]); // Store employee name suggestions
  const [isLoading, setIsLoading] = useState(false);
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    let updatedFormData = { ...formData, [name]: value };
  
    if (name === 'selectedDate') {
      const date = new Date(value);
      const options = { month: 'long' };
      const month = date.toLocaleDateString('en-US', options).toUpperCase();
      const year = date.getFullYear();
      const formattedDate = `${month} - ${year}`;
      updatedFormData.currentMonthYear = formattedDate;
      setSelectedDate(value);
    } else if (['grossSalary', 'basicSalary', 'houseRentAllowances', 'professionalAllowances', 'conveyance', 'otherAllowances', 'grosspay', 'totalDeductions', 'netPay', 'professionalTax', 'epf', 'eps'].includes(name)) {
      const parsedValue = parseInt(value.replace(/,/g, ''), 10) || 0;
      updatedFormData[name] = numberFormatter.format(parsedValue);
    } else {
      updatedFormData[name] = value;
    }
  
    if (name === 'totalWorkingDays' || name === 'paidDays') {
      const totalWorkingDays = parseInt(updatedFormData.totalWorkingDays, 10) || 0;
      const paidDays = parseInt(updatedFormData.paidDays, 10) || 0;
      updatedFormData.lopDays = totalWorkingDays - paidDays;
    }
  
    setFormData(updatedFormData);
    handleCalculate(updatedFormData);
  };
  
  const getPtaxMonthly = (annualSalary) => {
    const monthlySalary = annualSalary / 12;
    let ptaxMonthly = 0;
  
    if (monthlySalary <= 21000) {
      ptaxMonthly = 0;
    } else if (monthlySalary <= 30000) {
      ptaxMonthly = 135;
    } else if (monthlySalary <= 45000) {
      ptaxMonthly = 315;
    } else if (monthlySalary <= 60000) {
      ptaxMonthly = 690;
    } else if (monthlySalary <= 75000) {
      ptaxMonthly = 1025;
    } else {
      ptaxMonthly = 1250;
    }
  
    return ptaxMonthly;
  };
  useEffect(() => {
    const fetchAllEmployees = async () => {
      setIsLoading(true);
      setError(null);
  
      try {
        // Add your authorization token here
        const storedToken = localStorage.getItem('token');// Replace this with the actual token
  
        const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/fetchempname', {
          headers: {
            Authorization: `Bearer ${storedToken}`, // Authorization header
            'Content-Type': 'application/json', // Optional: Specify content type
          },
        });
        
        if (!response.ok) throw new Error('Failed to fetch employee records');
  
        const data = await response.json();
        setAllEmployeeRecords(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchAllEmployees();
  }, []);

  const handleNameChange = (e) => {
    const searchTerm = e.target.value;
    setFormData({ ...formData, employeeName: searchTerm });

    if (searchTerm.length > 1) {
      const filtered = allEmployeeRecords.filter((employee) =>
        employee.EmployeeName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredSuggestions(filtered);
    } else {
      setFilteredSuggestions([]);
    }
  };

  const handleSelectEmployee = async (name) => {
    setIsLoading(true);
    setError(null);
  
    try {
      // Add your authorization token here
      const storedToken = localStorage.getItem('token');// Replace this with the actual token
  
      const response = await fetch(`https://mrv1.indianwelfarefoundation.org.in/fetchemp/${name}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`, // Authorization header
          'Content-Type': 'application/json', // Optional: Specify content type
        },
      });
  
      if (!response.ok) throw new Error('Failed to fetch employee details');
  
      const data = await response.json();
      // Log the data to ensure the keys match
  
      // Update formData with the correct keys from the response
      setFormData({
        employeeName: data.EmployeeName || '',
        employeeCode: data.employeeCode || '',
        division: data.Division || '',
        zone: data.Zone || '',
        branch: data.Branch || '',
        location: data.Location || '',
        dateOfJoining: data.DateOfJoining || '',
        department: data.Department || '',
        designation: data.Designation || '',
        pfAccountNo: data.PFAccountNo || '',
        uanNumber: data.UANNumber || '',
        panNumber: data.PanNo || '',
        esicNumber: data.ESICNumber || '',
        bankAccountNo: data.BankAccountNo || '',
        bankName: data.BankName || '',
        totalWorkingDays: '',
        paidDays: '',
        lopDays: '',
        grossSalary: ''
      });
  
      setFilteredSuggestions([]);
      setIsLoading(false);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleCalculate = (data) => {
    const grossSalary = parseFloat(data.grossSalary.replace(/,/g, '')) || 0;
    const totalWorkingDays = parseInt(data.totalWorkingDays, 10) || 0;
    const lopDays = parseInt(data.lopDays, 10) || 0;
  
    if (totalWorkingDays === 0) return;
  
    const dailySalary = grossSalary / totalWorkingDays;
    const salaryForLopDays = dailySalary * lopDays;
    const adjustedGrossSalary = grossSalary - salaryForLopDays;
    const paidDays = totalWorkingDays - lopDays;
  
    const calculatedBasicSalary = Math.round(adjustedGrossSalary * 0.45);
    const calculatedHra = Math.round(adjustedGrossSalary * 0.20);
    const calculatedPa = Math.round(adjustedGrossSalary * 0.125);
    const calculatedConveyance = Math.round(adjustedGrossSalary * 0.165);
    const calculatedOtherAllowances = Math.round(adjustedGrossSalary * 0.06);
    const calculatedProfessionalTax = getPtaxMonthly(grossSalary * 12);
    const epf = Math.round(calculatedBasicSalary * 0.0833);
    const eps = Math.round(calculatedBasicSalary * 0.0367);
  
    const calculatedTotalDeductions = calculatedProfessionalTax + epf + eps;
    const calculatedGrossSalary = Math.round(calculatedBasicSalary + calculatedHra + calculatedPa + calculatedConveyance + calculatedOtherAllowances);
    const calculatedNetPay = Math.round(calculatedGrossSalary - calculatedTotalDeductions);
    const calculatedGrossDeductions = calculatedProfessionalTax + epf + eps+salaryForLopDays;

    let amountInWords = numberToWords.toWords(calculatedNetPay).replace(/,/g, '');
    amountInWords = amountInWords.charAt(0).toUpperCase() + amountInWords.slice(1) + ' Rupees Only';
  
    const numberFormatter = new Intl.NumberFormat('en-IN', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
  
    setFormData({
      ...data,
      basicSalary: numberFormatter.format(calculatedBasicSalary),
      houseRentAllowances: numberFormatter.format(calculatedHra),
      professionalAllowances: numberFormatter.format(calculatedPa),
      conveyance: numberFormatter.format(calculatedConveyance),
      otherAllowances: numberFormatter.format(calculatedOtherAllowances),
      professionalTax: numberFormatter.format(calculatedProfessionalTax),
      totalDeductions: numberFormatter.format(calculatedTotalDeductions),
      netPay: numberFormatter.format(calculatedNetPay),
      grosspay: numberFormatter.format(calculatedGrossSalary),
      otherdeductions:numberFormatter.format(salaryForLopDays),
      grossdeductions: numberFormatter.format(calculatedGrossDeductions),

      epf: numberFormatter.format(epf),
      eps: numberFormatter.format(eps),
      amountInWords: amountInWords
    });
  };
  
  const validateFormData = () => {
    const requiredFields = [
      'employeeName',
      'employeeCode',
      'designation',
      'department',
      'dateOfJoining',
      'grossSalary',
      'totalWorkingDays',
      'paidDays'
    ];
  
    for (let field of requiredFields) {
      if (!formData[field]) {
        return false;
      }
    }
    return true;
  };
  
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: async () => {
      setLoad(true);
      await sendFormData();
    }
  });
  
  const storedToken = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${storedToken}`,
  };
  
  const [error, setError] = useState('');
  
  const sendFormData = async () => {
    if (!validateFormData()) {
      setShow(true);
      setError('Please enter all the fields');
      setTimeout(() => {
        setShow(false);
        setLoad(false);
      }, 4000);
      return;
    }
    const formElement = componentRef.current;
    setLoad(true);
  
    const opt = {
      margin: 0.5,
      filename: 'offer_letter.pdf',
      image: { type: 'jpeg', quality: 0.80 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
  
    try {
      const pdfBlob = await html2pdf().from(formElement).set(opt).outputPdf('blob');
  
      const formDataToSend = new FormData();
      Object.keys(formData).forEach(key => {
        formDataToSend.append(key, formData[key]);
      });
  
      const storedToken = localStorage.getItem('token');
      const headers = {
        Authorization: `Bearer ${storedToken}`,
      };
  
      const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/payslip', {
        headers: headers,
        method: 'POST',
        body: formDataToSend,
      });
  
      const result = await response.json();
  
      if (response.ok && result.message === 'Payslip saved successfully') {
        setShow(true);
        setError('Payslip updated Successfully');
  
        setTimeout(() => {
          setShow(false);
          setLoad(false);
          navigate('/Fetchpay');
        }, 4000);
  
        setFormData({
          employeeName: '',
          employeeCode: '',
          designation: '',
          department: '',
          dateOfJoining: '',
          grossSalary: '',
          uan: '',
          totalWorkingDays: '',
          lopDays: '',
          paidDays: '',
          basicSalary: '',
          houseRentAllowances: '',
          professionalAllowances: '',
          conveyance: '',
          otherAllowances: '',
          professionalTax: '',
          epf: '',
          eps: '',
          totalDeductions: '',
          netPay: '',
          amountInWords: '',
          grosspay: '',
          otherdeductions:'',
          grossdeductions:'',
          currentMonthYear:''
        });
      } else {
        throw new Error('Unknown error in updating the payslip');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
      setShow(true);
      setError('An error occurred. Please try again.');
      setTimeout(() => {
        setShow(false);
        setLoad(false);
      }, 4000);
    }
  };
  
  useEffect(() => {
    const checkToken = async () => {
      if (!storedToken) {
        navigate('/LoginPage');
        return;
      }
  
      try {
        const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/validate-token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: storedToken }),
        });
  
        const data = await response.json();
  
        if (data.isValid) {
        } else {
          localStorage.removeItem('token');
          navigate('/LoginPage');
        }
      } catch (error) {
        localStorage.removeItem('token');
        navigate('/LoginPage');
      }
    };
  
    checkToken();
  }, [navigate]);
  
  useEffect(() => {
    if (!storedToken) {
      navigate('/LoginPage');
    }
  });
  
  const nav = () => {
    navigate('/Fetchpay');
  };
  
  return (
    <div>
      <NavbarMain />
      <span>
        {show && (
          <div className="thought">
            {error}
            <div className="progress-line"></div>
          </div>
        )}
      </span>
      <button onClick={nav} style={{ marginLeft: '20px', alignItems: 'start', marginTop: '20px', padding: '15px', textTransform: 'capitalize' }}>Payslips</button>
  
      <div className="App">
        <center><h2 style={{ color: 'red yellow ' }}>Payslip Generater</h2></center>
        <div style={{ backgroundColor: 'orange', borderRadius: '10px' }}>
          <p style={{ textAlign: 'left', padding: '10px', borderRadius: '10px', fontWeight: 'bold' }}>Basic Details</p>
        </div>
        <form>
         
  
          <label style={{ display: 'block', marginBottom: '10px' }}>
  Employee Name:
  <input
    className="employee-name-input"
    type="text"
    value={formData.employeeName}
    onChange={handleNameChange}
    placeholder="Search employee name..."
    style={{
      width: '100%',
      padding: '11px',
      marginTop: '5px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      boxSizing: 'border-box',
    }}
  />
  {filteredSuggestions.length > 0 ? (
  <div
    className="suggestions-list"
    style={{
      border: '1px solid #ccc',
      maxHeight: '250px',
      overflowY: 'auto',
      backgroundColor: '#fff',
      marginTop: '2px',
      position: 'absolute',  // Position absolutely to overlay on top of content
      zIndex: 1000,          // Ensure it appears above other content
      width: '23%',         // Maintain the width of the input field
    }}
  >
    {filteredSuggestions.map((employee) => (
      <div
        key={employee.EmployeeCode}
        onClick={() => handleSelectEmployee(employee.EmployeeName)}
        style={{
          padding: '8px',
          cursor: 'pointer',
          borderBottom: '1px solid #f0f0f0',
        }}
      >
        {employee.EmployeeName}
      </div>
    ))}
  </div>
) : (
  isLoading && (
    <p
      className="no-names-found"
      style={{
        marginTop: '5px',
        color: '#999',
      }}
    >
      No names found
    </p>
  )
)}
</label>
<label>
            Date:
            <input
              type="date"
              name="selectedDate"
              value={selectedDate}
              onChange={handleFormChange}
              required
            />
          </label>
          <label>Employee Code: <input type="text" name="employeeId" value={formData.employeeCode} disabled onChange={handleFormChange} required /></label>
          <label>
            Division:
            <input type="text" name="division" value={formData.division} disabled />
          </label>
          <label>
            Zone:
            <input type="text" name="zone" value={formData.zone} disabled />
          </label>
          <label>
            Branch:
            <input type="text" name="branch" value={formData.branch} disabled />
          </label>
          <label>
            Location:
            <input type="text" name="location" value={formData.location} disabled />
          </label>
          <label>
            Date of Joining:
            <input type="text" name="dateOfJoining" value={formData.dateOfJoining} disabled />
          </label>
          <label>
            Department:
            <input type="text" name="department" value={formData.department} disabled />
          </label>
          <label>
            Designation:
            <input type="text" name="designation" value={formData.designation} disabled />
          </label>
          <label>Total Working Days: <input type="text" name="totalWorkingDays" value={formData.totalWorkingDays} onChange={handleFormChange} required /></label>
          <label>Paid Days: <input type="text" name="paidDays" value={formData.paidDays} onChange={handleFormChange} required /></label>

          <label>LOP Days: <input type="text" name="lopDays" value={formData.lopDays} disabled /></label>
          <label>Gross Salary: <input type="text" name="grossSalary" value={formData.grossSalary} onChange={handleFormChange} required /></label>
        </form>
        <br></br>
  
        <div className='fixedcontainer' style={{ marginBottom: '300px' }}>
          <OfferLetter ref={componentRef} formData={formData} className="offer" sendFormData={sendFormData} isEnabled={isEnabled} currentMonthYear={formData.currentMonthYear}
            loading={load} setIsEnabled={setIsEnabled} />
  
        </div>
      </div>
    </div>
  );
  };
  
  export default Pdf;
  