import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { numberToWords } from 'number-to-words'; // For amount in words
import './edit.css'; // Assuming you have your CSS for styling
import NavbarMain from './NavbarMain';
const Editpayslip = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    employeeName: '',
    employeeCode: '',
    designation: '',
    department: '',
    dateOfJoining: '',
    grossSalary: '',
    uan: '',
    totalWorkingDays: '',
    lopDays: '',
    paidDays: '',
    basicSalary: '',
    houseRentAllowances: '',
    professionalAllowances: '',
    conveyance: '',
    otherAllowances: '',
    professionalTax: '',
    epf: '',
    eps: '',
    totalDeductions: '',
    netPay: '',
    amountInWords: '',
    grosspay: '',
    otherdeductions: '',
    grossdeductions: '',
    currentMonthYear: ''
  });

  const [isVerified, setIsVerified] = useState(false);
  const [passcode, setPasscode] = useState('');
  const [error, setError] = useState('');
  const [load, setLoad] = useState(false);

  // Fetch data from localStorage on component mount
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('editData'));
    console.log(storedData)
    if (storedData) {
      setFormData(storedData);
    }else{
        navigate('/Fetchpay')
    }

  }, []);

  // Handle input changes for editable fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));

    if (name === 'grossSalary' || name === 'totalWorkingDays' || name === 'lopDays') {
      performCalculations({ ...formData, [name]: value });
    }
  };
  const storedToken = localStorage.getItem('token')
  useEffect(() => {
    const checkToken = async () => {


      if (!storedToken) {
        navigate('/LoginPage');
        return;
      }

      try {
        const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/validate-token', {
          method: 'POST',
          headers: {

            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: storedToken }),
        });

        const data = await response.json();

        if (data.isValid) {
          
        } else {
          localStorage.removeItem('token')
          
          navigate('/LoginPage');
        }
      } catch (error) {
        localStorage.removeItem('token')

       
        navigate('/LoginPage');
      }
    };

    checkToken();
  }, [navigate]);
  useEffect(() => {
    if (!storedToken) {
      navigate('/LoginPage');
    }
  })
  // Perform salary calculations
  const performCalculations = (data) => {
    const grossSalary = parseFloat(data.grossSalary.replace(/,/g, '')) || 0;
    const totalWorkingDays = parseInt(data.totalWorkingDays, 10) || 0;
    const lopDays = parseInt(data.lopDays, 10) || 0;

    if (totalWorkingDays === 0) return;

    const dailySalary = grossSalary / totalWorkingDays;
    const salaryForLopDays = dailySalary * lopDays;
    const adjustedGrossSalary = grossSalary - salaryForLopDays;
    const paidDays = totalWorkingDays - lopDays;

    const calculatedBasicSalary = Math.round(adjustedGrossSalary * 0.45);
    const calculatedHra = Math.round(adjustedGrossSalary * 0.20);
    const calculatedPa = Math.round(adjustedGrossSalary * 0.125);
    const calculatedConveyance = Math.round(adjustedGrossSalary * 0.165);
    const calculatedOtherAllowances = Math.round(adjustedGrossSalary * 0.06);
    const calculatedProfessionalTax = getPtaxMonthly(grossSalary * 12);
    const epf = Math.round(calculatedBasicSalary * 0.0833);
    const eps = Math.round(calculatedBasicSalary * 0.0367);

    const calculatedTotalDeductions = calculatedProfessionalTax + epf + eps;
    const calculatedGrossSalary = Math.round(calculatedBasicSalary + calculatedHra + calculatedPa + calculatedConveyance + calculatedOtherAllowances);
    const calculatedNetPay = Math.round(calculatedGrossSalary - calculatedTotalDeductions);
    const calculatedGrossDeductions = calculatedProfessionalTax + epf + eps + salaryForLopDays;

    let amountInWords = numberToWords.toWords(calculatedNetPay).replace(/,/g, '');
    amountInWords = amountInWords.charAt(0).toUpperCase() + amountInWords.slice(1) + ' Rupees Only';

    const numberFormatter = new Intl.NumberFormat('en-IN', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });

    setFormData({
      ...data,
      basicSalary: numberFormatter.format(calculatedBasicSalary),
      houseRentAllowances: numberFormatter.format(calculatedHra),
      professionalAllowances: numberFormatter.format(calculatedPa),
      conveyance: numberFormatter.format(calculatedConveyance),
      otherAllowances: numberFormatter.format(calculatedOtherAllowances),
      professionalTax: numberFormatter.format(calculatedProfessionalTax),
      totalDeductions: numberFormatter.format(calculatedTotalDeductions),
      netPay: numberFormatter.format(calculatedNetPay),
      grosspay: numberFormatter.format(calculatedGrossSalary),
      otherdeductions: numberFormatter.format(salaryForLopDays),
      grossdeductions: numberFormatter.format(calculatedGrossDeductions),
      epf: numberFormatter.format(epf),
      eps: numberFormatter.format(eps),
      amountInWords: amountInWords
    });
  };
  const [show, setShow] = useState(false);

  // Helper function for professional tax calculation (mockup)
  const getPtaxMonthly = (annualSalary) => {
    // Professional tax logic based on salary slabs
    if (annualSalary > 250000) return 200; // example slab
    return 0;
  };
  const formatLabel = (key) => {
    return key
      .replace(/([A-Z])/g, ' $1') // Add space before capital letters
      .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter
      .replace(/([A-Z])/g, ' $1') // Add space before capital letters
      .trim(); // Remove any leading/trailing spaces
  };
  
  const handleVerify = async () => {
    setLoad(true)
    if (!passcode) {
      setError('Please enter the passcode');
      setLoad(false)
      return;
    }
    try {
      const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/verifypass', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify({ passcode: passcode }),
      });

      const data = await response.json();

      if (data.isValid) {
        setIsVerified(true);
      
        setError('');
        setLoad(false)

      } else {
        setError('Invalid passcode');
        setLoad(false)

      }
    } catch (error) {
      setLoad(false)

      console.error('Error verifying passcode:', error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);

    const { id, ...updateData } = formData;
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`https://mrv1.indianwelfarefoundation.org.in/updatepayslip/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(updateData),
      });

      if (response.ok) {
        setTimeout(() => {
          setShow(true);
          setError('Joining letter updated Successfully');

          setTimeout(() => {
            setShow(false);
            setLoad(false);
            localStorage.removeItem('editData')
            navigate('/Fetchpay');
          }, 4000);
        }, 10);
      } else {
        setTimeout(() => {
          setLoad(false);
          setShow(true);
          setError('Unknown error in updating the joining letter');

          setTimeout(() => {
            setShow(false);
          }, 4000);
        }, 10);
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };
  useEffect(() => {
    const checkToken = async () => {
      if (!storedToken) {
        navigate('/LoginPage');
        return;
      }

      try {
        const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/validate-token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: storedToken }),
        });

        const data = await response.json();
        if (!data.isValid)  {
          localStorage.removeItem('token');
          navigate('/LoginPage');
        }
      } catch (error) {
        localStorage.removeItem('token');
        navigate('/LoginPage');
      }
    };

    checkToken();
  }, [navigate, storedToken]);
  return (
    <div>
        <NavbarMain />
      <span>
        {show && (
          <div className="thought">
            {error}
            <div className="progress-line"></div>
          </div>
        )}
      </span>
    <div className="edit-page">
      <h2>Edit Employee Record</h2>
      <form onSubmit={handleSubmit} className="edit-form">
  {Object.keys(formData).map((key) => {
    // Define fields to hide
    const fieldsToHide = [
      'id', 
      'BankAccountNo', 
      'BankName', 
      'Branch', 
      'Department', 
      'Designation', 
      'Division', 
      'Location', 
      'Zone', 
      'created_at'
    ];

    // Skip rendering hidden fields
    if (fieldsToHide.includes(key)) {
      return null;
    }

    return (
      <div key={key} className="form-group">
        <label>{formatLabel(key)}:</label>
        <input
          type={key === 'grossSalary' }
          name={key}
          value={formData[key]}
          onChange={handleChange}
          disabled={key !== 'grossSalary' && key !== 'totalWorkingDays' && key !== 'lopDays'}
        />
      </div>
    );
  })}

  {!isVerified ? (
    <div>
      <input
        type="text"
        value={passcode}
        onChange={(e) => setPasscode(e.target.value)}
        placeholder="Enter verification code"
        style={{  padding: '10px', fontWeight: 'bold', width: '200px' }}
      />
      <button
        type="button"
        onClick={handleVerify}
        style={{ height: 'fit-content', padding: '5px', fontWeight: 'bold', width: '120px', marginLeft: '10px' }}
      >
        Verify
      </button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  ) : (
    <button
      type="submit"
      style={{ height: 'fit-content', padding: '5px', fontWeight: 'bold', width: '120px' }}
    >
      {load ? (
        <center>
          <div className="loading-spinner"></div>
        </center>
      ) : (
        'Update'
      )}
    </button>
  )}
</form>

    </div></div>
  );
};

export default Editpayslip;
