import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavbarMain from './NavbarMain';
import AnimatedBackground from './Animated'; // Import the background

const Dashboard = () => {
  const navigate = useNavigate();
  const storedToken = localStorage.getItem('token');

  useEffect(() => {
    const checkToken = async () => {
      if (!storedToken) {
        navigate('/LoginPage');
        return;
      }

      try {
        const response = await fetch('https://mrv1.indianwelfarefoundation.org.in/validate-token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: storedToken }),
        });

        const data = await response.json();

        if (!data.isValid) {
          localStorage.removeItem('token');
          navigate('/LoginPage');
        }
      } catch (error) {
        localStorage.removeItem('token');
        navigate('/LoginPage');
      }
    };

    checkToken();
  }, [navigate]);

  const handleGenerateNewOfferLetter = () => {
    navigate('/pdf');
  };

  const handleViewGeneratedOfferLetters = () => {
    navigate('/offer');
  };

  const handleViewGeneratedJoinLetters = () => {
    navigate('/Joining');
  };

  const genpay = () => {
    navigate('/Payslip');
  };

  const payslip = () => {
    navigate('/Fetchpay');
  };

  return (
    <div>
      <NavbarMain />
      <AnimatedBackground />
      <div className="dashboard-container">
        <div className="button-container">
          <button onClick={handleGenerateNewOfferLetter} className="dashboard-button">
            Generate New Offer Letter
          </button>
          <button onClick={handleViewGeneratedOfferLetters} className="dashboard-button">
            Offer Letters Generated
          </button>
          <button onClick={handleViewGeneratedJoinLetters} className="dashboard-button">
            Joining Letters Received
          </button>
          <button onClick={genpay} className="dashboard-button">
            Generate Payslip
          </button>
          <button onClick={payslip} className="dashboard-button">
            Payslips
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
