import React, { useEffect, useRef } from 'react';

const Animated = () => {
  const canvasRef = useRef(null);
  const largeHeaderRef = useRef(null);

  useEffect(() => {
    let width, height, ctx, points, target, animateHeader = true;

    // Initialize canvas and points
    const initHeader = () => {
      width = window.innerWidth;
      height = window.innerHeight;
      target = { x: width / 2, y: height / 2 };

      const largeHeader = largeHeaderRef.current;
      largeHeader.style.height = `${height}px`;

      const canvas = canvasRef.current;
      canvas.width = width;
      canvas.height = height;
      ctx = canvas.getContext('2d');

      // Create points
      points = [];
      for (let x = 0; x < width; x += width / 20) {
        for (let y = 0; y < height; y += height / 20) {
          const px = x + Math.random() * width / 20;
          const py = y + Math.random() * height / 20;
          const p = { x: px, originX: px, y: py, originY: py };
          points.push(p);
        }
      }

      // Find the 5 closest points for each point
      points.forEach((p1) => {
        const closest = [];
        points.forEach((p2) => {
          if (p1 !== p2) {
            if (closest.length < 5) {
              closest.push(p2);
            } else {
              for (let i = 0; i < 5; i++) {
                if (getDistance(p1, p2) < getDistance(p1, closest[i])) {
                  closest[i] = p2;
                  break;
                }
              }
            }
          }
        });
        p1.closest = closest;
      });

      // Assign a circle to each point
      points.forEach((point) => {
        point.circle = new Circle(point, 2 + Math.random() * 2, 'rgba(255,255,255,0.3)');
      });
    };

    // Animation logic
    const animate = () => {
      if (animateHeader) {
        ctx.clearRect(0, 0, width, height);
        points.forEach((point) => {
          if (Math.abs(getDistance(target, point)) < 4000) {
            point.active = 0.3;
            point.circle.active = 0.6;
          } else if (Math.abs(getDistance(target, point)) < 20000) {
            point.active = 0.1;
            point.circle.active = 0.3;
          } else if (Math.abs(getDistance(target, point)) < 40000) {
            point.active = 0.02;
            point.circle.active = 0.1;
          } else {
            point.active = 0;
            point.circle.active = 0;
          }

          drawLines(point);
          point.circle.draw();
        });
      }
      requestAnimationFrame(animate);
    };

    // Helper functions
    const drawLines = (p) => {
      if (!p.active) return;
      p.closest.forEach((closestPoint) => {
        ctx.beginPath();
        ctx.moveTo(p.x, p.y);
        ctx.lineTo(closestPoint.x, closestPoint.y);
        ctx.strokeStyle = `rgba(156,217,249,${p.active})`;
        ctx.stroke();
      });
    };

    const getDistance = (p1, p2) => {
      return Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2);
    };

    function Circle(pos, rad, color) {
      this.pos = pos;
      this.radius = rad;
      this.color = color;
      this.active = 0;

      this.draw = function () {
        if (!this.active) return;
        ctx.beginPath();
        ctx.arc(this.pos.x, this.pos.y, this.radius, 0, 2 * Math.PI, false);
        ctx.fillStyle = `rgba(156,217,249,${this.active})`;
        ctx.fill();
      };
    }

    const mouseMove = (e) => {
      const posx = e.pageX || e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
      const posy = e.pageY || e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
      target.x = posx;
      target.y = posy;
    };

    const resize = () => {
      width = window.innerWidth;
      height = window.innerHeight;
      largeHeaderRef.current.style.height = `${height}px`;
      canvasRef.current.width = width;
      canvasRef.current.height = height;
    };

    const addListeners = () => {
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('resize', resize);
    };

    // Initialize everything
    initHeader();
    animate();
    addListeners();

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener('mousemove', mouseMove);
      window.removeEventListener('resize', resize);
    };
  }, []);

  return (
    <div id="large-header" className="large-header" ref={largeHeaderRef}>
      <canvas id="demo-canvas" ref={canvasRef}></canvas>
      <h1 className="main-title">Connect <span className="thin">Three</span></h1>
    </div>
  );
};

export default Animated;
